import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import moment from 'moment';
import store from "./redux";
import useVh from "./hooks/useVh";
import 'react-calendar/dist/Calendar.css';
import "./lib/bootstrap";
import "./styles/typography.scss";
import "./styles/template.scss";
import "./styles/calendar.scss";
import DoReservationModal, { openDoReservationModal } from "./components/DoReservationModal";
require('moment/locale/nl.js');
moment.locale('nl');

const root = document.getElementById("easyzeats-widget");

const App = () => {
	useVh();

	const [ label, setLabel ] = useState<string>('Reserveren');

	const id = root?.dataset.restaurant || '0';

	useEffect(() => {
		window.addEventListener('cancel-reservation', () => setLabel('Reserveren'), true);
        return () => {
            window.removeEventListener('cancel-reservation', () => setLabel('Reserveren'), true);
        }
	}, []);

	const handleOpen = (): void => {
		openDoReservationModal(id, undefined, true);
		setLabel('EasyZeats');
	}

	return (<>
		<div
			onClick={() => handleOpen()}
			style={{ position: 'fixed', bottom: 32, right: 32, zIndex: 9998, display: 'flex', height: 40, alignItems: 'center', borderRadius: 6, backgroundColor: '#979f73', boxShadow: '2px 1px 4px rgba(0, 0, 0, .3)', cursor: 'pointer' }}
		>
			<div style={{ width: 40, height: 40, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
				<img src="https://widget.easyzeats.nl/logo.svg" alt="EasyZeats" style={{ display: 'block', height: 24 }} />
			</div>
			<div style={{ flex: 1, paddingRight: 16, color: '#ffffff', fontSize: 14 }}>
				{label}
			</div>
		</div>
		<DoReservationModal />
	</>);
};

ReactDOM.render(
	<Provider store={store}>
		<App />
	</Provider>,
	root
);
