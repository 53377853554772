import axios from 'axios';
import moment from 'moment';
import { capitalize, range } from 'lodash';
import React, { FC, useCallback, useEffect, useState } from 'react';
import Calendar from 'react-calendar';
import { ApiReservation } from '../../api/reservation';
import Button from '../../kit/Button';
import Form from '../../kit/Form';
import styles from './styles.module.scss';
import FullscreenLoader from '../../kit/FullscreenLoader';
import Message from '../../kit/Message';
import useGet from '../../hooks/rest/useGet';
import { useAuth } from '../../hooks/useAuth';
import CheckBox from '../CheckBox';
import Modal from '../../kit/Modal';
import { Link } from 'react-router-dom';
import Switch from '../Switch';

interface DoReservationFormProps {
    edit?: ApiReservation,
    restaurantId: string,
    step: number,
    onUpdate: (reservation: Partial<ApiReservation>) => void,
}

const DoReservationForm: FC<DoReservationFormProps> = ({
    edit,
    restaurantId,
    step,
    onUpdate
}): JSX.Element | null => {
    const auth = useAuth();
    const [ date, setDate ] = useState<string>(edit ? edit.date : moment().format('YYYY-MM-DD'));
    const [ errors, setErrors ] = useState<any>({});
    const [ isLoading, setIsLoading ] = useState<boolean>(false);
    const [ showNeedModal, setShowNeedModal ] = useState<boolean>(false);
    const [ toggleTimes, setToggleTimes ] = useState<boolean>(false);
    const [ toggleCapacity, setToggleCapacity ] = useState<boolean>(false);
    const [ diffToday, setDiffToday ] = useState<number>(0);
    const [ category, setCategory ] = useState<string>('lunch');
    const [ showCalendar, setShowCalendar ] = useState<boolean>(false);
    const [ slots, setSlots ] = useState<any[]>([]);
    const [ timeSlotId, setTimeSlotId ] = useState<number>();
    const [ extraFill, setExtraFill ] = useState<string[]>([]);
    const [ restaurant ] = useGet(`/restaurants/${restaurantId}/widget`);
    const [ reservation, setReservation ] = useState<Partial<ApiReservation>>(edit || {
        persons: 1,
        date: moment().format('YYYY-MM-DD'),
        dietary_needs: [],
    });

    useEffect(() => {
        setCategory((['Ontbijt', 'Brunch', 'Lunch', 'Diner'].filter(filterCategories)[0] || 'lunch').toLowerCase());
    }, [auth, restaurant]);

    useEffect(() => {
        setDiffToday(Math.abs(moment(date).diff(moment(), 'days')));
    }, [date]);

    useEffect(() => {
        onUpdate(reservation);
    }, [reservation]); // eslint-disable-line

    useEffect(() => {
        if (slots.length > 0 && restaurant && restaurant.data && edit) {
            handlePersons(edit.persons || 1);
        }
    }, [restaurant, edit, slots]); // eslint-disable-line

    useEffect(() => {
        const save = ({ detail }: any): void => {
            setErrors({});
            setIsLoading(true);
            const url = timeSlotId || (reservation.timeslot && reservation.timeslot.modus === 'slot')
                ? `/restaurants/${restaurant.data.id}/timeslots/${timeSlotId || reservation.timeslot.id}/reservations`
                : `/restaurants/${restaurant.data.id}/timeslots/frequencies/reservation`

            axios.post(url, {
                ...reservation,
                dietary_needs: (reservation.dietary_needs || []).map((o) => o.id),
                validate: detail.isFront || false,
            }).then(() => {
                setIsLoading(false);
                dispatchEvent(new CustomEvent('reservation-refresh'));
                dispatchEvent(new CustomEvent('show-success'));
            }).catch((err) => {
                setIsLoading(false);
                setErrors(err.response.data.errors);
            });
        }

        window.addEventListener('do-form-reservation', save, true);
        return () => window.removeEventListener('do-form-reservation', save, true);
    }, [reservation, restaurant, timeSlotId]);

    const fetchSlots = useCallback(() => {
        if (!restaurant || !restaurant.data || !restaurant.data.id) return;
        setIsLoading(true);
        axios.get(`/restaurants/${restaurant.data.id}/timeslots/frequencies?date=${date}&category=${category}&rid=${reservation.id ? reservation.id : ''}`).then(({ data }) => {
            setSlots(data);
            setIsLoading(false);
        });
    }, [category, date, restaurant]);

    useEffect(() => {
        fetchSlots();
    }, [fetchSlots]);

    const handleDate = (date: string): void => {
        setReservation({ ...reservation, date });
        setDate(date);
        setShowCalendar(false);
        setDiffToday(Math.abs(moment(date).diff(moment(), 'days')));
    }

    const handlePersons = (persons: number): void => {
        setReservation({ ...reservation, persons });

        if (!reservation.set_timeslot || restaurant.data.modus === 'slot') {
            return;
        }

        let fillUp: string[] = [];
        let capacityNeeded = persons;
        const time = parseInt(reservation.set_timeslot.replace(':', ''));

        slots.forEach((s) => {
            const stime = parseInt(s.time.replace(':', ''));
            if (stime >= time && capacityNeeded > 0) {
                capacityNeeded -= s.capacity;
                fillUp.push(s.time);
            }
        });

        setExtraFill(fillUp);
    }

    const handleSlot = (slot: any): void => {
        if ((reservation.persons || 1) > slot.capacity && !auth) {
            return;
        }

        setReservation({
            ...reservation,
            set_timeslot: slot.time,
            timeslot_id: slot.timeSlotId,
        });
        if (restaurant.data.modus === 'slot') {
            setTimeSlotId(slot.timeSlotId);
        } else {
            let fillUp: string[] = [];
            let capacityNeeded = (reservation.persons || 1) - slot.capacity;
            const time = parseInt(slot.time.replace(':', ''));

            slots.forEach((s) => {
                const stime = parseInt(s.time.replace(':', ''));
                if (stime > time && capacityNeeded > 0) {
                    capacityNeeded -= s.capacity;
                    fillUp.push(s.time);
                }
            });

            if (fillUp.length > 0) {
                setToggleTimes(true);
            }
            setExtraFill(fillUp);
        }
    }

    const handleInput = ({ name, value }: any): void => {
        setReservation({
            ...reservation,
           [name]: value,
        });
    }

    const handleNeeds = (need: any, state: boolean) => {
        let dietary_needs = [ ...(reservation.dietary_needs || []) ];
        
        if (state) {
            dietary_needs.push(need);
        } else {
            dietary_needs = dietary_needs.filter((o) => o.id !== need.id);
        }

        setReservation({
            ...reservation,
           dietary_needs,
        });
    }

    const filterCategories = (item: string): boolean => {
        if (!auth?.restaurant && restaurant && restaurant.data && (!restaurant.data.availableCategories || !restaurant.data.availableCategories.includes(item))) {
            return false;
        }

        if (
            auth?.restaurant || // ingelogd
            reservation.date !== moment().format('YYYY-MM-DD') // niet vandaag
        ) {
            return true;
        }

        const h = new Date().getHours();

        if (item === 'Ontbijt' && h < 11) {
            return true;
        } else if (item === 'Brunch' && h < 13) {
            return true;
        } else if (item === 'Lunch' && h < 16) {
            return true;
        } else if (item === 'Diner') {
            return true;
        }

        return false;
    }

    const renderSlots = (): JSX.Element => {
        let personsLeft = reservation.persons || 1;

        return (<>
            <div className={`${styles.formSlots} ${toggleTimes ? styles.full : ''} ${styles[`formSlots${restaurant.data.modus}`]}`}>
                {slots.map((slot, index) => {
                    let slotCapacity = slot.capacity;
                    
                    if (
                        reservation.set_timeslot === slot.time ||
                        (restaurant.data.modus === 'slot' && reservation.timeslot?.id === slot.timeSlotId)
                    ) {
                        slotCapacity -= !auth?.restaurant && personsLeft > slotCapacity ? slotCapacity : personsLeft;
                        personsLeft -= slot.capacity;
                    } else if (extraFill.includes(slot.time)) {
                        slotCapacity -= personsLeft > slotCapacity ? slotCapacity : personsLeft;
                        personsLeft -= slot.capacity;
                    }
                    
                    return (
                        <div
                            key={`cap-${slot.time}`}
                            className={[
                                styles.formSlot,
                                reservation.set_timeslot === slot.time ||
                                (restaurant.data.modus === 'slot' && reservation.timeslot?.id === slot.timeSlotId)
                                    ? styles.isActive
                                    : '',
                                reservation.set_timeslot !== slot.time && extraFill.includes(slot.time) ? styles.extraFill : '',
                                (reservation.persons || 1) > slotCapacity && !auth ? styles.isDisabled : '',
                                slotCapacity === 0 && auth ? styles.zero : '',
                                slotCapacity < 0 && auth ? styles.minus : '',
                            ].join(' ')}
                            onClick={() => handleSlot(slot)}
                        >
                            {slot.time}
                            {auth && <span>{(slotCapacity)}</span>}
                        </div>
                    );
                })}
            </div>
            {slots.length > (restaurant.data.modus === 'freq' ? 5 : 3) && (<div
                className={styles.toggle}
                onClick={() => setToggleTimes(!toggleTimes)}
            >
                {toggleTimes ? 'Minder' : 'Meer'} tijden
                <i className={`far fa-chevron-${toggleTimes ? 'up' : 'down'}`} />
            </div>)}
        </>)
    }

    if (!restaurant || !restaurant.data) {
        return null;
    }

    return (
        <div style={{ position: 'relative' }}>
            <FullscreenLoader
                absolute
                state={isLoading}
            />
            {(step === 0) && (<>
                {restaurant.data.modus === 'freq' && (<>
                    <div className={styles.formHeader}>
                        Wat wil je doen?
                    </div>
                    <div className={styles.formSection}>
                        <Form.Dropdown
                            options={['Ontbijt', 'Brunch', 'Lunch', 'Diner'].filter(filterCategories).map((o) => ({
                                text: o,
                                value: o.toLowerCase(),
                            }))}
                            onChange={({ value }: any) => setCategory(value)}
                            value={category}
                        />
                    </div>
                </>)}
                <div className={styles.formHeader}>
                    Aantal personen
                </div>
                <div className={styles.formSection}>
                    <div className={`${styles.formCapacity} ${toggleCapacity ? styles.full : ''}`}>
                        {range(1, ((restaurant.data.settings || {}).max_capacity || 20) + 1).map((o) => (
                            <div
                                key={`cap-${o}`}
                                className={`${styles.formPerson} ${parseInt(`${reservation.persons}`) === o ? styles.isActive : ''}`}
                                onClick={() => handlePersons(o)}
                            >
                                <div>
                                    {o}
                                </div>
                            </div>
                        ))}
                    </div>
                    {((restaurant.data.settings || {}).max_capacity || 20) > 6 && (<div
                        className={styles.toggle}
                        onClick={() => setToggleCapacity(!toggleCapacity)}
                    >
                        {toggleCapacity ? 'Minder' : 'Meer'} personen
                        <i className={`far fa-chevron-${toggleCapacity ? 'up' : 'down'}`} />
                    </div>)}
                </div>
                <div className={styles.formHeader}>
                    Kies een dag
                </div>
                <div className={styles.formSection}>
                    <div className={styles.flexButtons}>
                        <Button
                            label="Vandaag"
                            color={reservation.date === moment().format('YYYY-MM-DD') ? 'green' : 'grey'}
                            block
                            onClick={() => handleDate(moment().format('YYYY-MM-DD'))}
                        />
                        <Button
                            label="Morgen"
                            color={reservation.date === moment().add(1, 'day').format('YYYY-MM-DD') ? 'green' : 'grey'}
                            block
                            onClick={() => handleDate(moment().add(1, 'day').format('YYYY-MM-DD'))}
                        />
                    </div>
                    <Button
                        label={diffToday >= 1 ? moment(reservation.date).format('dddd DD MMMM') : 'Andere dag'}
                        color={diffToday >= 1 ? 'green' : 'grey'}
                        block
                        onClick={() => setShowCalendar(true)}
                    />
                    {showCalendar && (
                        <div className={styles.calendar}>
                            <Calendar
                                className="mw-calendar"
                                minDate={moment().add(2, 'day').toDate()}
                                locale="nl-NL"
                                nextLabel={<i className="far fa-chevron-right" />}
                                next2Label={null}
                                onChange={(date: any) => handleDate(moment(date).format('YYYY-MM-DD'))}
                                prevLabel={<i className="far fa-chevron-left" />}
                                prev2Label={null}
                                value={moment(reservation.date).toDate()}
                            />
                        </div>
                    )}
                </div>
                <div className={styles.formSection}>
                    <div className={styles.formHeader}>
                        Kies een tijd
                    </div>
                    {slots.length > 0 ? renderSlots() : (
                        <Message 
                            type={auth?.restaurant ? 'error' : 'message'}
                            content={auth?.restaurant
                                ? (restaurant.data.modus === 'freq'
                                    ? <span>Er is nog geen tijdfrequentie aangemaakt voor de categorie <b>{capitalize(category)}</b>. Ga naar Instellingen &gt; <Link onClick={() => dispatchEvent(new CustomEvent('reservation-refresh'))} to="/dashboard/settings/online" style={{ color: '#BC0000' }}>Online reserveren</Link></span>
                                    : 'Er is nog geen tijdvak aangemaakt voor deze dag. Ga naar Instellingen > Online reserveren')
                                : 'Er zijn geen beschikbare tijden voor de geselecteerd datum'
                            }
                        />
                    )}
                </div>
            </>)}

            {step === 1 && (<>
                <p className={styles.formConfirmation}>
                    Reservering voor <b>{reservation.persons} pers{reservation.persons === 1 ? 'oon' : 'onen'}</b>,<br />
                    op <b>{moment(reservation.date).format('DD MMMM')}</b> {restaurant.data.modus === 'freq' ? 'om' : 'tussen'} <b>{(reservation.set_timeslot || '')}</b>.
                </p>
                <div className={styles.formHeader}>
                    Uw gegevens
                </div>
                <div className={styles.formSection}>
                    <Form.Input
                        error={errors.name}
                        name="name"
                        placeholder="Naam"
                        onChange={handleInput}
                        value={reservation.name || ''}
                    />
                    <Form.Input
                        error={errors.email}
                        name="email"
                        placeholder="E-mailadres"
                        onChange={handleInput}
                        value={reservation.email || ''}
                        type="email"
                    />
                    <Form.Input
                        error={errors.telephone}
                        name="telephone"
                        placeholder="Mobiel"
                        onChange={handleInput}
                        value={reservation.telephone || ''}
                    />
                    {restaurant && restaurant.data && (!restaurant.data.settings || restaurant.data.settings.allow_comment) && (
                        <Form.Textarea
                            name="comment"
                            placeholder="Opmerking"
                            onChange={handleInput}
                            value={reservation.comment || ''}
                            autoGrow
                        />
                    )}
                    {restaurant && restaurant.data && (!restaurant.data.settings || restaurant.data.settings.require_household) && (
                        <div>
                            <Switch
                                onChange={(val) => handleInput({ name: 'is_same_household', value: val })}
                                value={reservation.is_same_household === undefined ? true : reservation.is_same_household}
                                aan="Ja"
                                uit="Nee"
                                label="Bestaat het gezelschap uit één huishouden?"
                                full
                            />
                            {reservation.is_same_household && (
                                <Form.Textarea
                                    name="household_comment"
                                    placeholder="Samenstelling"
                                    onChange={handleInput}
                                    value={reservation.household_comment || ''}
                                    autoGrow
                                />
                            )}
                        </div>
                    )}
                </div>
                {restaurant.data.dietary_needs.length > 0 && (<>
                    <div className={styles.formHeader}>
                        Dieëtwensen
                    </div>
                    <div className={styles.formSection}>
                        <div className={styles.needs}>
                            <Button
                                color={reservation.dietary_needs && reservation.dietary_needs.length > 0
                                    ? 'grey'
                                    : 'green'
                                }
                                block
                                label="Geen"
                                onClick={() => handleInput({ name: 'dietary_needs', value: [] }) }
                            />
                            <Button
                                color={!reservation.dietary_needs || reservation.dietary_needs.length <= 0
                                    ? 'grey'
                                    : 'green'
                                }
                                block
                                label="Selecteren"
                                onClick={() => setShowNeedModal(true)}
                            />
                        </div>
                        {reservation.dietary_needs?.map((need) => (
                            <span
                                key={`dn-${need.id}`}
                                className={styles.needTag}
                            >
                                {need.name}
                            </span>
                        ))}
                    </div>
                    <Modal
                        className={styles.needsModal}
                        header="Dieëtwensen"
                        onClose={() => setShowNeedModal(false)}
                        open={showNeedModal}
                        approveBtn="Opslaan"
                    >
                        <p>Laat ons weten of wij rekening moeten houden met dieëtwensen en/of allergieën.</p>
                        {restaurant.data.dietary_needs.map((need: any) => (
                            <div style={{ marginBottom: 16 }} key={`need-${need.id}`}>
                                <CheckBox
                                    label={need.name}
                                    onChange={(v) => handleNeeds(need, v)}
                                    value={reservation.dietary_needs?.includes(need)}
                                />
                            </div>
                        ))}
                    </Modal>
                </>)}
            </>)}
        </div>
    );
}

export default DoReservationForm;
