import axios from 'axios';
import React, { FC, useEffect, useState } from 'react';
import { ApiReservation } from '../../api';
import Modal from '../../kit/Modal';
import { toast } from '../../kit/NotificationCenter';
import { showSuccess } from '../Success';
import DoReservationForm from './Form';
import styles from './styles.module.scss';

export const openDoReservationModal = (
    restaurantId: string,
    reservation?: ApiReservation,
    isFront: boolean = false,
    date?: string,
    cancel?: boolean,
): void => {
    dispatchEvent(new CustomEvent('open-do-reservation-modal', {
        detail: {
            restaurantId,
            reservation,
            isFront,
            date,
            cancel,
        },
    }));
}

const DoReservationModal: FC = (): JSX.Element => {
    const [ reservation, setReservation ] = useState<ApiReservation>();
    const [ restaurantId, setRestaurantId ] = useState<string>();
    const [ step, setStep ] = useState<number>(0);
    const [ open, setOpen ] = useState<boolean>(false);
    const [ isFront, setIsFront ] = useState<boolean>(false);
    const [ cancel, setCancel ] = useState<boolean>(false);
    const [ wip, setWip ] = useState<Partial<ApiReservation>>();

    useEffect(() => {
        const handleOpen = ({ detail }: any): void => {
            setRestaurantId(detail.restaurantId);
            setCancel(detail.cancel === true ? true : false);
            if (detail.reservation) {
                setReservation(detail.reservation);
            } else if (detail.date) {
                setReservation({
                    persons: 1,
                    date: detail.date,
                    dietary_needs: [],
                } as any);
            } else {
                setReservation(undefined);
            }
            setIsFront(detail.isFront);
            setStep(0);
            setOpen(true);
        }

        const handleClose = (): void => {
            setOpen(false);
            setIsFront(false);
            setStep(0);
            setRestaurantId(undefined);
            setReservation(undefined);
            setCancel(false);
            dispatchEvent(new CustomEvent('cancel-reservation'));
        }

        window.addEventListener('open-do-reservation-modal', handleOpen, true);
        window.addEventListener('reservation-refresh', handleClose, true);
        return () => {
            window.removeEventListener('open-do-reservation-modal', handleOpen, true);
            window.removeEventListener('reservation-refresh', handleClose, true);
        }
    }, []);

    return (
        <Modal
            className={styles.formMod}
            header={reservation?.id ? `Reservering ${cancel ? 'annuleren' : 'wijzigen'}` : 'Reserveren'}
            open={open}
            onClose={() => dispatchEvent(new CustomEvent('reservation-refresh'))}
            onCancel={() => {
                if (cancel) {
                    setCancel(false);
                    return false;
                }

                if (step === 1) {
                    setStep(0);
                    return false;
                }
                return true;
            }}
            cancelBtn={step === 1 ? 'Terug' : (cancel ? 'Reservering wijzigingen' : 'Annuleren')}
            approveBtn={step === 0 ? (cancel ? 'Ja, annuleren' : 'Volgende') : (reservation ? 'Opslaan' : 'Reserveren')}
            preventClose
            onApprove={() => {
                if (cancel && reservation) {
                    axios.delete(`/reservation/${reservation.key}`).then(() => {
                        showSuccess({
                            title: 'Uw reservering is geannuleerd',
                        });
                        dispatchEvent(new CustomEvent('reservation-refresh'));
                    });
                } else {
                    if (step === 0) {
                        if (wip?.set_timeslot || wip?.timeslot) {
                            setStep(1);
                        } else {
                            toast({
                                header: 'Oeps',
                                content: 'Je hebt geen tijd gekozen',
                                onTop: true,
                            });
                        }
                    } else {
                        dispatchEvent(new CustomEvent('do-form-reservation', { detail: { isFront } }));
                    }
                }
            }}
        >
            {cancel && (
                <div className={styles.cancel}>
                    Weet je zeker dat je deze reservering wilt annuleren?
                </div>
            )}
            {!cancel && restaurantId && (
                <DoReservationForm
                    restaurantId={restaurantId}
                    step={step}
                    edit={reservation}
                    onUpdate={(r) => setWip(r)}
                />
            )}
        </Modal>
    );
}

export default DoReservationModal;
